
import 'babel-polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import "./element"// 饿了吗 ui
import "./assets/icons" //svg
import store from "./store/index.js"
import infiniteScroll from 'vue-infinite-scroll'

import elCascaderMulti from "el-cascader-multi";
Vue.use(elCascaderMulti);

Vue.use(infiniteScroll)

Vue.config.productionTip = false




router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token') ? true : false
  //一进来的主页
  if (from.path === '/') {
    next()
    //你要是一步一步玩，我正义的化身绝不拦你 http://localhost:8080/
    if (  to.path.indexOf("pay_notify_xcx") != -1  || to.path === '/login' || to.path === '/register' || to.path === '/elite_resume' || to.path === '/elite_login' || to.path === '/author' || to.path === '/source_update' || to.path === '/elite_position' ||
      to.path === '/elite_company' || to.path === '/elite_company_details' || to.path === '/elite_position_details' || to.path === '/elite_chat' || to.path === '/img/' || to.path === '/images/') {
      next()
    } else
      //但是你要是没有令牌,并且随意闯入大殿，滚回入口
      if (!token && to.path != '/') {
        next('/login')
      } else {
        //你有令牌，你随便搞
        next()
      }
  } else {
    next()
  }
})


new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)
import message from "./modules/message"
import tool from "./modules/tool"
import company from "./modules/company"


export default new Vuex.Store({
  modules: {
    message, tool, company
  },
  state: {
    company_logo: '',
    company_name: ''
  }
  ,
  mutations: {
    changeLogo(state, payload) {
      console.log(payload)
      state.company_logo = payload.logo
      state.company_name = payload.name
    }
  }

})

// 行业筛选
const profession = [
  {
    title: '行业不限',
    code: 0,
    position_id: [],
  },
  {
    title: '房地产',
    code: 1,
    position_id: [220000],
  },
  {
    title: '金融',
    code: 2,
    position_id: [180000],
  },
  {
    title: '旅游',
    code: 3,
    position_id: [280000],
  },
  {
    title: '零售/快消',
    code: 4,
    position_id: [160000],
  },
  {
    title: '教育/培训',
    code: 5,
    position_id: [190000],
  },
  {
    title: '运动/健身',
    code: 6,
    position_id: [210000],
  },
  {
    title: '汽车',
    code: 7,
    position_id: [300000],
  },
  {
    title: '互联网',
    code: 8,
    position_id: [100000, 160000],
  },
]
//学历
const educationBackground = [
  {
    label: '学历不限',
    value: '',
  },
  {
    label: '初中',
    value: '初中',
  },
  {
    label: '中专',
    value: '中专',
  },
  {
    label: '高中',
    value: '高中',
  },
  {
    label: '大专',
    value: '大专',
  },
  {
    label: '本科',
    value: '本科',
  },
  {
    label: '硕士',
    value: '硕士',
  },
  {
    label: '博士',
    value: '博士',
  },
  {
    label: 'MBA',
    value: 'MBA',
  },
]
//年龄
const age = [
  {
    label: '年龄不限',
    value: '0',
  },
  {
    label: '18-35岁',
    value: '1',
  },
  {
    label: '18-21岁',
    value: '2',
  },
  {
    label: '22-27岁',
    value: '3',
  },
  {
    label: '28-35岁',
    value: '4',
  },
  {
    label: '36-45岁',
    value: '5',
  },
  {
    label: '45岁以上',
    value: '6',
  },
]
//性别
const sex = [
  {
    label: '性别不限',
    value: '0',
  },
  {
    label: '男',
    value: '1',
  },
  {
    label: '女',
    value: '2',
  },
]
//薪资待遇
const pay = [
  {
    label: '0-3000元',
    value: '0',
  },
  {
    label: '3000-5000元',
    value: '1',
  },
  {
    label: '5000-10000元',
    value: '2',
  },
  {
    label: '10000-15000元',
    value: '3',
  },
  {
    label: '15000-20000元',
    value: '4',
  },
  {
    label: '20000-30000元',
    value: '5',
  },
  {
    label: '30000-50000元',
    value: '6',
  },
]

//公司人数规模
const company_scale = [
  {
    label: '0-20人',
    value: '0',
  },
  {
    label: '20-99人',
    value: '1',
  },
  {
    label: '100-200人',
    value: '2',
  },
  {
    label: '201-999人',
    value: '3',
  },
  {
    label: '1000人以上',
    value: '4',
  },
]
//工作经验
const work_time = [
  {
    label: '经验不限',
    value: '0',
  },
  {
    label: '1年之内',
    value: '1',
  },
  {
    label: '1-3年',
    value: '2',
  },
  {
    label: '3-5年',
    value: '3',
  },
  {
    label: '5年以上',
    value: '4',
  },
]

export {
  profession,
  educationBackground,
  age,
  sex,
  pay,
  company_scale,
  work_time,
}

let module = {
  namespaced: true,
  state: {
    //true  layout 出现黑罩 填写表单
    isPublishForm: false,
    publishInfo: {},
    publishUpdate: false
  },
  mutations: {
    emitPublishForm(state, payload) {
      state.isPublishForm = payload.isPublishForm
      state.publishInfo = payload.info
      state.publishUpdate = payload.update
      // console.log(state.publishUpdate)


    }
  }
}
export default module
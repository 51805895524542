import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routes = [
  //首页
  {
    path: '/',
    name: 'main',
    component: () => import('@/pages/home'),
  },
  {
    path: '/elite_position',
    name: 'elite_position',
    component: () => import('@/pages/home/elite_position'),
  },
  {
    path: '/elite_company',
    name: 'elite_company',
    component: () => import('@/pages/home/elite_company'),
  },
  {
    path: '/elite_company_details',
    name: 'elite_company_details',
    component: () => import('@/pages/home/elite_company_details'),
  },
  {
    path: '/elite_position_details',
    name: 'elite_position_details',
    component: () => import('@/pages/home/elite_position_details'),
  },
  {
    path: '/elite_resume',
    name: 'elite_resume',
    component: () => import('@/pages/home/elite_resume'),
  },
  {
    path: '/elite_chat',
    name: 'elite_chat',
    component: () => import('@/pages/home/elite_chat'),
  },

  {
    path: '/pay_notify_xcx',
    name: 'pay_notify_xcx',
    component: () => import('@/pages/home/pay_notify_xcx'),
  },





  //登录
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/me/login/login'),
  },

  //注册
  {
    path: '/register',
    name: 'register',
    component: () => import('@/pages/me/login/register'),
  },

  //精英登录
  {
    path: '/elite_login',
    name: 'elite_login',
    component: () => import('@/pages/home/elite_login'),
  },

  {
    path: '/author',
    name: 'author',
    component: () => import('@/pages/me/author'),
  },
  //协议与隐私条款
  {
    path: '/agree',
    name: 'agree',
    component: () => import('@/pages/public/agree/agree'),
  },
  {
    path: '/conceal',
    name: 'conceal',
    component: () => import('@/pages/public/agree/conceal'),
  },

  // 更新简历信息
  {
    path: '/source_update',
    name: 'source_update',
    component: () => import('@/pages/public/source_update'),
  },

  // 创建账户
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/pages/public/layout'),
    children: [
      {
        path: '/add_user',
        name: 'add_user',
        component: () => import('@/pages/user_mena/add_user'),
        meta: { title: '创建账户', parentUrl: '/user_mena' },
      },
      {
        path: '/Import_resume',
        name: 'Import_resume',
        component: () => import('@/pages/user_mena/Import_resume'),
        meta: { title: '导入简历', parentUrl: '/user_mena' },
      },
      {
        path: '/delete_jd',
        name: 'delete_jd',
        component: () => import('@/pages/user_mena/delete_jd'),
        meta: { title: '删除简历', parentUrl: '/user_mena' },
      },
      {
        path: '/poster_upload',
        name: 'poster_upload',
        component: () => import('@/pages/user_mena/poster_upload'),
        meta: { title: '海报更新', parentUrl: '/user_mena' },
      },
      {
        path: '/video_upload',
        name: 'video_upload',
        component: () => import('@/pages/user_mena/video_upload'),
        meta: { title: '视频更新', parentUrl: '/user_mena' },
      },
      {
        path: '/regis_mena',
        name: 'regis_mena',
        component: () => import('@/pages/user_mena/regis_mena'),
        meta: { title: '注册管理', parentUrl: '/user_mena' },
      },
      {
        path: '/restore_resume',
        name: 'restore_resume',
        component: () => import('@/pages/user_mena/restore_resume'),
        meta: { title: '还原密码', parentUrl: '/user_mena' },
      },
      {
        path: '/search_user',
        name: 'search_user',
        component: () => import('@/pages/user_mena/search_user'),
        meta: { title: '用户查询', parentUrl: '/user_mena' },
      },


      {
        path: '/user_change',
        name: 'user_change',
        component: () => import('@/pages/user_mena/user_change'),
        meta: { title: '修改帐号密码', parentUrl: '/user_mena' },
      },

      {
        path: '/setup',
        name: 'setup',
        component: () => import('@/pages/setup/setup'),
        meta: { title: '设置', parentUrl: '/setup' },
      },
      {
        path: '/group',
        name: 'group',
        component: () => import('@/pages/setup/group'),
        meta: { title: '套餐', parentUrl: '/group' },
      },



    ],
  },
  //公司展示
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/pages/public/layout'),

    children: [
      {
        path: '/jobdetails',
        name: 'jobdetails',
        component: () => import('@/pages/company/jobDetails'),
        meta: { title: '返回', only: true, go: -1, parentUrl: '/company' },
      },
      {
        path: '/company',
        name: 'company',
        component: () => import('@/pages/company/company'),
        meta: {
          title: '公司展示',
          keepAlive: true,
          parentUrl: '/company',
        },
      },
    ],
  },
  //招聘工具
  {
    path: '/layout',
    component: () => import('@/pages/public/layout'),
    children: [
      {
        path: '/radar',
        name: 'radar',
        component: () => import('@/pages/tool/radar'),
        meta: { title: '简历雷达', parentUrl: '/tool' },
      },

      {
        path: '/createcode',
        name: 'createcode',
        component: () => import('@/pages/tool/createcode'),
        meta: { title: '生成二维码', parentUrl: '/tool' },
      },
      {
        path: '/resumemanage',
        name: 'resumemanage',
        component: () => import('@/pages/tool/resumemanage'),
        meta: { title: '简历管理', keepAlive: true, parentUrl: '/tool' },
      },
      {
        path: '/tool',
        name: 'tool',
        component: () => import('@/pages/tool/publish'),
        meta: { title: 'JD设置', parentUrl: '/tool' },
      },
      {
        path: '/tabulate',
        name: 'tabulate',
        component: () => import('@/pages/tool/tabulate'),
        meta: { title: '数据汇总', parentUrl: '/tool' },
      },
      {
        path: '/audition',
        name: 'audition',
        component: () => import("@/pages/tool/audition"),
        meta: {
          title: '面试管理', parentUrl: '/audition'
        },
      },

      //猎场简历详情
      {
        path: '/tooldetails',
        name: 'tooldetails',
        title: '咖聘',
        component: () => import('@/pages/tool/toolDetails'),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/tool',
        },
      },
    ],
  },
  //猎场
  {
    path: '/layout',
    component: () => import('@/pages/public/layout'),
    children: [
      //筛选展示广场
      {
        path: '/resume',
        name: 'resume',
        component: () => import('@/pages/resume/resume'),
        meta: { title: '猎场', keepAlive: true, parentUrl: '/resume' },
      },

      //猎场简历详情
      {
        path: '/resumedetails',
        name: 'resumedetails',
        component: () => import('@/pages/resume/resumeDetails'),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/resume',
        },
      },

      //简历库
      {
        path: '/resumebank',
        name: 'resumebank',
        component: () => import('@/pages/resume/resumeBank'),
        meta: { title: '简历库', parentUrl: '/resume' },
      },
      {
        path: '/radarbank',
        name: 'radarbank',
        component: () => import('@/pages/resume/radarbank'),
        meta: { title: '雷达库', parentUrl: '/resume' },
      },
      //猎场简历库详情
      {
        path: '/resumebankdetails',
        name: 'resumebankdetails',
        component: () => import('@/pages/resume/resumeBankDetails'),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/resume',
        },
      },

       //猎场小简历简历库详情
       {
        path: '/leichangDetails',
        name: 'leichangDetails',
        component: () => import('@/pages/resume/leichangDetails'),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/resume',
        },
      },

    ],
  },
  //推广互动
  {
    path: '/layout',
    component: () => import('@/pages/public/layout'),
    children: [
      //推广海报
      {
        path: '/promotion',
        name: 'promotion',
        component: () => import('@/pages/promotion/poster'),
        meta: { title: '推广海报', keepAlive: true, parentUrl: '/promotion' },
      },
      //分享视频
      {
        path: '/share',
        name: 'share',
        component: () => import('@/pages/promotion/video'),
        meta: { title: '分享视频', parentUrl: '/promotion' },
      },
    ],
  },
  //我的
  {
    path: '/layout',
    // name: "layout",
    component: () => import('@/pages/public/layout'),
    children: [
      {
        path: '/me',
        name: 'me',
        component: () => import('@/pages/me/mine'),
        meta: { title: '我的', parentUrl: '/me' },
      },
      {
        // 使用手册
        path: '/instruction',
        name: 'instruction',
        component: () => import('@/pages/me/instruction'),
        meta: { title: '使用手册', parentUrl: '/me' },
      },
      {
        //设置公司展示
        path: '/setshow',
        name: 'setshow',
        component: () => import('@/pages/me/setShow'),
        meta: { title: '设置公司展示', parentUrl: '/me' },
        // redirect: '/setshow/baseInformation',
        // children: [{
        //   path: "baseInformation",
        //   name: "baseInformation",
        //   component: () => import("./../components/setShow/baseInformation.vue"),
        // },
        // {
        //   path: "hotman",
        //   name: "hotman",
        //   component: () => import("./../components/setShow/hotMan.vue"),
        // },
        // {
        //   path: "teamimg",
        //   name: "teamimg",
        //   component: () => import("./../components/setShow/teamShow.vue"),
        // }
        // ]
      },
      {
        //用户反馈
        path: '/feedback',
        name: 'feedback',
        component: () => import('@/pages/me/feedback'),
        meta: { title: '用户反馈', parentUrl: '/me' },
      },
    ],
  },


  //积分商城 后台
  {
    path: '/shop',
    component: () => import('@/pages/public/layout'),
    children: [

      {
        path: '/shop_goods',
        name: 'shop_goods',
        component: () => import('@/pages/jifen/shop_goods'),
        meta: { title: '积分商城', parentUrl: '/shop_goods' },
      },
      // {
      //   path: '/shop_add_order',
      //   name: 'shop_add_order',
      //   component: () => import('@/pages/jifen/shop_add_order'),
      //   meta: { title: '添加订单', parentUrl: '/shop_add_order' },
      // },
      // {
      //   path: '/shop_get_order',
      //   name: 'shop_get_order',
      //   component: () => import('@/pages/jifen/shop_get_order'),
      //   meta: { title: '订单详情', parentUrl: '/shop_get_order' },
      // },
      {
        path: '/shop_search_order',
        name: 'shop_search_order',
        component: () => import('@/pages/jifen/shop_search_order'),
        meta: { title: '订单查询', parentUrl: '/shop_search_order' },
      },
      {
        path: '/shop_search_goods',
        name: 'shop_search_goods',
        component: () => import('@/pages/jifen/shop_search_goods'),
        meta: { title: '商品管理', parentUrl: '/shop_search_goods' },
      },
    ]
  },

  //积分商城 用户
  {
    path: '/user_shop',
    component: () => import('@/pages/public/layout'),
    children: [

      {
        path: '/shop_user_goods',
        name: 'shop_user_goods',
        component: () => import('@/pages/jifen/shop_goods'),
        meta: { title: '积分商城', parentUrl: '/shop_goods' },
      },
      // {
      //   path: '/shop_add_order',
      //   name: 'shop_add_order',
      //   component: () => import('@/pages/jifen/shop_add_order'),
      //   meta: { title: '添加订单', parentUrl: '/shop_add_order' },
      // },
      {
        path: '/shop_get_order',
        name: 'shop_get_order',
        component: () => import('@/pages/jifen/shop_get_order'),
        meta: { title: '订单详情', parentUrl: '/shop_get_order' },
      },
      // {
      //   path: '/shop_search_order',
      //   name: 'shop_search_order',
      //   component: () => import('@/pages/jifen/shop_search_order'),
      //   meta: { title: '订单查询', parentUrl: '/shop_search_order' },
      // },
      // {
      //   path: '/shop_update_order',
      //   name: 'shop_update_order',
      //   component: () => import('@/pages/jifen/shop_update_order'),
      //   meta: { title: '审核订单', parentUrl: '/shop_update_order' },
      // },
    ]
  },


  {
    path: '/layout',
    component: () => import('@/pages/public/layout'),
    children: [
      {
        path: '/data_board',
        name: 'data_board',
        component: () => import('@/pages/data_board/data_board'),
        meta: { title: '数据看板', parentUrl: '/data_board' },
      },
      {
        path: '/data_link',
        name: 'data_link',
        component: () => import('@/pages/data_board/data_link'),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/data_board',
        },
      },

      {
        path: '/data_table',
        name: 'data_table',
        component: () => import('@/pages/data_board/data_table'),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/data_board',
        },
      },
      {
        path: '/city_table',
        name: 'city_table',
        component: () => import('@/pages/data_board/city_table'),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/data_board',
        },
      },

      //数据看板简历详情
      {
        path: '/resumedetails2',
        name: 'resumedetails2',
        component: () => import('@/pages/data_board/resumeDetails2'),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/data_board',
        },
      },

    ],
  },

  {
    path: '/agent',
    component: () => import('@/pages/public/layout'),
    children: [


      {
        path: '/search_agent',
        name: 'search_agent',
        component: () => import('@/pages/agent/search_agent'),
        meta: { title: '经纪人管理', parentUrl: '/agent' },
      },
      {
        path: '/jd_agent_list',
        name: 'jd_agent_list',
        component: () => import('@/pages/agent/jd_agent_list'),
        meta: { title: '悬赏职位管理', parentUrl: '/agent' },
      },
      {
        path: '/jd_agent_add',
        name: 'jd_agent_add',
        component: () => import('@/pages/agent/jd_agent_add'),
        meta: { title: '添加悬赏职位', parentUrl: '/agent' },
      },
      {
        path: '/jd_agent',
        name: 'jd_agent',
        component: () => import('@/pages/agent/jd_agent'),
        meta: { title: '职位投递管理', parentUrl: '/agent' },
      },



    ],
  },

  {
    path: '/agent_company',
    component: () => import('@/pages/public/layout'),
    children: [

      

      // {
      //   path: '/agent_company_by_phone',
      //   name: 'agent_company_by_phone',
      //   component: () => import('@/pages/agent/agent_company_by_phone'),
      //   meta: { title: '审核经纪人', parentUrl: '/agent_company' },
      // },

      {
        path: '/agent_company_code',
        name: 'agent_company_code',
        component: () => import('@/pages/agent/agent_company_code'),
        meta: { title: '生成经纪人推广码', parentUrl: '/agent_company_code' },
      },

      {
        path: '/agent_company',
        name: 'agent_company',
        component: () => import('@/pages/agent/agent_company'),
        meta: { title: '经纪人管理', parentUrl: '/agent_company' },
      },
 
      {
        path: '/jd_agent_company',
        name: 'jd_agent_company',
        component: () => import('@/pages/agent/jd_agent_company'),
        meta: { title: '职位投递管理', parentUrl: '/agent_company' },
      },



    ],
  },


  {
    path: '/chat',
    component: () => import('@/pages/public/layout'),
    children: [


      {
        path: '/chatlist',
        name: 'chatlist',
        component: () => import('@/pages/chat/chatlist'),
        meta: { title: '聊一聊', parentUrl: '/chat' },
      },
      {
        path: '/chatdetail',
        name: 'chatdetail',
        component: () => import('@/pages/chat/chatdetail'),
        meta: { title: '聊天', parentUrl: '/chat' },
      },


    ],
  },



  {
    path: '/chat_gl',
    component: () => import('@/pages/public/layout'),
    children: [


      {
        path: '/chatlist_search',
        name: 'chatlist_search',
        component: () => import('@/pages/chat_gl/chatlist_search'),
        meta: { title: '聊一聊管理', parentUrl: '/chat_gl' },
      },

      {
        path: '/chatdetail_gl',
        name: 'chatdetail_gl',
        component: () => import('@/pages/chat_gl/chatdetail'),
        meta: { title: '聊天', parentUrl: '/chat_gl' },
      },


    ],
  },



  //特殊线索
  {
    path: '/xiansuo',
    component: () => import('@/pages/public/layout'),
    children: [

      {
        path: '/tsxiansuo',
        name: 'tsxiansuo',
        component: () => import('@/pages/tool/tsxiansuo'),
        meta: { title: '特殊线索', parentUrl: '/xiansuo' },
      },
      {
        path: '/addtsxiansuo',
        name: 'addtsxiansuo',
        component: () => import('@/pages/tool/addtsxiansuo'),
        meta: { title: '添加特殊线索', parentUrl: '/xiansuo' },
      },
      {
        path: '/listtsxiansuo',
        name: 'listtsxiansuo',
        component: () => import('@/pages/tool/listtsxiansuo'),
        meta: { title: '特殊线索列表', parentUrl: '/xiansuo' },
      },
    ]
  },


  //积分管理
  {
    path: '/jifen',
    component: () => import('@/pages/public/layout'),
    children: [

      {
        path: '/search_jifen',
        name: 'search_jifen',
        component: () => import('@/pages/jifen/search_jifen'),
        meta: { title: '积分查询', parentUrl: '/search_jifen' },
      },
      {
        path: '/add_jifen',
        name: 'add_jifen',
        component: () => import('@/pages/jifen/add_jifen'),
        meta: { title: '添加积分', parentUrl: '/add_jifen' },
      },
      {
        path: '/update_jifen',
        name: 'update_jifen',
        component: () => import('@/pages/jifen/update_jifen'),
        meta: { title: '兑换积分', parentUrl: '/update_jifen' },
      },
      {
        path: '/search_zhifu',
        name: 'search_zhifu',
        component: () => import('@/pages/jifen/search_zhifu'),
        meta: { title: '在线充值记录', parentUrl: '/search_zhifu' },
      },

    ]
  },

 //导出职位
 {
  path: '/daochu_jd',
  component: () => import('@/pages/public/layout'),
  children: [

    {
      path: '/daochu_jd',
      name: 'daochu_jd',
      component: () => import('@/pages/daochu_jd/daochu_jd'),
      meta: { title: '导出职位', parentUrl: '/daochu_jd' },
    },
     
  ]
},


  

  {
    path: '/error',
    name: 'error',
    component: () => import('@/pages/public/404'),
  },
]
export default new Router({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  base: '/dist',
  routes,
})

import { getCompanyShow } from '@/api/company/company'
import { work_time, educationBackground } from './../../utils/searchtype'

let module = {
  namespaced: true,
  state: {
    company_data: {},
    jd_data: []
  },
  mutations: {
    getCompanyShowData(state, payload) {
      console.log(payload)
      getCompanyShow(payload.type).then(res => {
        if (res.code === 0) {
          console.log(res, "进没进来")
          if (payload.type === 'all') {

            if (res.data.company_introduce != undefined) {
              res.data.company_introduce = res.data.company_introduce.replace(
                /\n/g,
                '<br/>'
              )
            }

            res.data.sign_data.filter(item => {
              item.sign_introduce = item.sign_introduce.replace(/\n/g, '<br/>')
            })
            state.company_data = res.data
          }

          if (payload.succeed && typeof payload.succeed === "function") {
            payload.succeed(res)
          }
        }
      })
    }
  }
  ,
  actions: {
    getCompanyShowData(conText, payload) {
      conText.commit('getCompanyShowData', payload)
    }
  }
}
export default module
import { require } from '././../../utils/http'
//公司展示
function getCompanyShow(data) {
  let obj = {
    models_info: data,
  }
  return require('post', '/api/kapin_ee/company/v1/company_info/', obj)
}
//公司展示jd详情
function getCompanyjdinfo(data) {
  let obj = {
    jd_id: data.id,
  }
  return require('post', '/api/kapin_ee/company/v1/jd_info/', obj)
}
export { getCompanyShow, getCompanyjdinfo }

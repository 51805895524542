import axios from 'axios'
// axios.defaults.timeout = 7000
// import Vue from 'vue'
// import Router from './../router'
import { Loading, Message, Notification } from 'element-ui'
var loadinginstace
// 添加请求拦截器
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    //

    if (sessionStorage.getItem('token')) {
      config.headers.token = sessionStorage.getItem('token')
    }

    console.log(config)
    return config
  },
  (error) => {
    // 对请求错误做些什么
    // console.log(error)
    // loadinginstace = Loading.service({ fullscreen: true })
    // Message.error({

    //   message: '加载超时'

    // })
    // loadinginstace.close()
    return Promise.reject(error)
  }
)

// 添加响应拦截器s
axios.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    // console.log(loadinginstace)
    // loadinginstace.close()
    console.log(!response.data.code)

    // 多设备登录跳到登录页
    if (response.data.code == 110006) {
      Message.error({
        message: response.data.msg,
      })
    }

    return response.data
  },
  function(error) {
    // 对响应错误做点什么
    // loadinginstace = Loading.service({ fullscreen: true })
    // let a = new Vue()
    // console.log(a)
    // console.log(Router.push('/'))

    Message.error({
      message: '加载超时,请刷新',
    })
    // loadinginstace.close()
    return Promise.reject(error)
  }
)

function require(type, url, data) {
  console.log(url)
  let option = new URLSearchParams()
  if (data instanceof Object) {
    for (var prop in data) {
      if (data[prop] instanceof Object) {
        option.append(prop, JSON.stringify(data[prop]))
      } else {
        option.append(prop, data[prop])
      }
    }
  }

  let ajaxConfig = {
    method: type,
    url: url,
    data: option,
    proxy: true, //支持代理解决跨域
  }
  console.log(ajaxConfig)
  return axios(ajaxConfig)
}
export { require }

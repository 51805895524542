import { require } from './../../utils/http'
//猎场

function getResumeData(data) {
  return require('post', '/api/kapin_ee/liechang/v1/liechang_list/', data)
}
//猎场详情

function getResumeDetails(data) {
  return require('post', '/api/kapin_ee/liechang/v1/liechang_detail/', data)
}
//猎场详情

function downResume(data) {
  return require('post', '/api/kapin_ee/liechang/v1/download_resume/', data)
}

//展示城市数据
function showCity(data) {
  return require('post', '/api/public/v1/show_place_json/', {})
}

//展示行业
function showIndustry(data) {
  return require('post', '/api/public/v1/show_industry_json/', data)
}


//展示地区
function showCity2(data) {
  return require('post', '/api/public/v1/show_city_json/', data)
}



//展示职位
function showPosition(data) {
  return require('post', '/api/public/v1/show_position_json/', data)
}


//行业设置 包括添加，删除，修改名称
function onindustry(data) {
  return require('post', '/api/public/v1/onindustry/', data)
}

//职位设置 包括添加，删除，修改名称
function onposition(data) {
  return require('post', '/api/public/v1/onposition/', data)
}




//查询
function getsearchResumeData(data) {
  // let obj = {
  //   scroll_id: data.scroll_id,
  //   page: data.page,
  //   expect_place_id: data.expect_place_id,
  //   search_str: data.search_str
  // }
  return require('post', '/api/kapin_ee/liechang/v1/liechang_list/', data)
}
//简历库
function getResumeBankData(data) {
  let obj = {
    page: data.page,
  }
  return require('post', '/api/kapin_ee/liechang/v1/resume_list/', obj)
}
//简历库详情
function getResumeBankDetailsData(data) {
  let obj = {
    resume_id: data.resume_id,
  }
  return require('post', '/api/kapin_ee/liechang/v1/resume_detail/', obj)
}
//猎场中小简历详情
function getliechang_detail(data) {
  let obj = {
    elite_id: data.elite_id,
  }
  return require('post', '/api/kapin_ee/liechang/v1/getliechang_detail/', obj)
}
 



//简历是否已下载
function getIsDown() {
  return require('post', '/api/kapin_ee/liechang/v1/liechang_download/')
}
//雷达列表
function getradar(data) {
  return require('post', '/api/kapin_ee/tool/v1/radar_all_list_pc/', data)
}
//下载雷达线索
function downradar(data) {
  return require('post', '/api/kapin_ee/tool/v1/download_radar/', data)
}

//猎场中下载雷达线索
function resume_downradar(data) {
  return require('post', '/api/kapin_ee/tool/v1/resume_downradar/', data)
}



//查询所有地区（设置页面用的）
function get_region(data) {
  return require('post', '/api/public/v1/get_region/', data)
}

//保存修改后的地区（设置页面用的）
function edit_region(data) {
  return require('post', '/api/public/v1/edit_region/', data)
}


//添加用户组
function add_user_group(data) {
  return require('post', '/api/kapin_ee/user/v1/add_user_group/', data)
}

//修改用户组
function delete_user_group(data) {
  return require('post', '/api/kapin_ee/user/v1/delete_user_group/', data)
}
//获取用户组
function get_user_group(data) {
  return require('post', '/api/kapin_ee/user/v1/get_user_group/', data)
}

//获取公司地区
function get_company_place(data) {
  return require('post', '/api/kapin_ee/company/v1/get_company_place/', data)
}

//在线支付
function qrcode_pay(data) {
  return require('post', '/api/kapin_ee/liechang/v1/qrcode_pay/', data)
}
//获取当前登录用户积分user
function get_integral(data) {
  return require('post', '/api/kapin_ee/user/v1/get_integral/', data)
}

 
//获取当前支付状态
function get_zhifu_state(data) {
  return require('post', '/api/kapin_ee/liechang/v1/get_zhifu_state/', data)
}






export {
  getResumeData,
  getResumeBankData,
  showCity,
  getsearchResumeData,
  getResumeBankDetailsData,
  getResumeDetails,
  downResume,
  getIsDown,
  getradar,
  downradar,
  showIndustry,
  showPosition,
  onindustry,
  onposition,
  showCity2,
  get_region,
  edit_region,
  add_user_group,
  delete_user_group,
  get_user_group,
  get_company_place,
  qrcode_pay,
  get_integral,
  get_zhifu_state,
  resume_downradar,
  getliechang_detail
}

<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view v-if="is_new"></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "app",
  provide() {
    return {
      news: this.news,
    };
  },

  data() {
    return {
      is_new: true,
    };
  },

  methods: {
    news() {
      this.is_new = false;
      this.$nextTick(function () {
        this.is_new = true;
      });
    },
  },
};
</script>

<style>
@import "./assets/css/public.css";
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
